<template>
  <el-dialog class="dialog"
             title="填写快手号和手机号"
             :width="'500px'"
             :visible="dialogPhoneForm"
             top="0"
             :show-close="false"
             @close="closeForm">
    <el-form :model="form"
             ref="form"
             :hide-required-asterisk="true"
             label-position="right"
             label-width="70px"
             :rules="rules">
      <el-form-item label="快手号："
                    prop="tick_account">
        <el-input v-model="form.tick_account"
                  placeholder="请输入快手号"
                  autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号："
                    prop="phone">
        <el-input v-model="form.phone"
                  maxlength="11"
                  placeholder="请输入手机号"
                  autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button class="form_btn"
                 type="primary"
                 @click="handleStore">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogPhoneForm: {
      type: Boolean,
      defaults: false
    }
  },
  data () {
    return {
      form: {
        tick_account: null,
        phone: null
      },
      rules: {
        tick_account: [
          { required: true, message: '请输入快手号', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号长度应为11位', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleStore () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$emit('anchorPhoneModal', this.form)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closeForm () {
      this.$emit('closeForm')
    }
  }
}
</script>

<style lang="less" scoped>
.dialog /deep/ .el-dialog__body {
  padding-bottom: 0;
}
.dialog /deep/.el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>