/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-28 11:15:29
 * @Module: 新增主播
 */
 <template>
  <el-card style="padding-right:160px"
           class="anchor-form-style">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="80px"
             :size="'mini'"
             label-position="top">
      <el-row :gutter="90">
        <el-col :span="12">
          <el-form-item label="快手号："
                        prop="tick_account"
                        :error="errorMessage?errorMessage.tick_account:''">
            <el-input v-model="form.tick_account"
                      readonly
                      placeholder="请输入快手号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-role="['administrator']">
          <el-form-item label="经纪人："
                        prop="broker_id"
                        :error="errorMessage?errorMessage.broker_id:''">
            <brokerInviter @change="id=>form.broker_id = id"
                           placeholder="请选择经纪人"
                           class="width100"></brokerInviter>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公会："
                        prop="mcn_id"
                        :error="errorMessage?errorMessage.mcn_id:''">
            <mcn class="width100"
                 :filterable="false"
                 @change="id=>form.mcn_id=id"
                 placeholder="请选择公会" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="真实姓名："
                        prop="name"
                        :error="errorMessage?errorMessage.name:''">
            <el-input v-model="form.name"
                      placeholder="请输入真实姓名">
              <el-tooltip effect="dark"
                          placement="top-start"
                          slot="suffix"
                          content="快手实名认证姓名">
                <div style="cursor: pointer;line-height:40px;">
                  <span class="icon iconfont icon-wenhao"
                        style="color:#509DED"></span>
                </div>
              </el-tooltip>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话："
                        prop="phone"
                        :error="errorMessage?errorMessage.phone:''">
            <el-input v-model="form.phone"
                      readonly
                      placeholder="请输入电话">
              <el-tooltip effect="dark"
                          placement="top-start"
                          slot="suffix"
                          content="快手绑定的手机号">
                <div style="cursor: pointer;line-height:40px;">
                  <span class="icon iconfont icon-wenhao"
                        style="color:#509DED"></span>
                </div>
              </el-tooltip>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分成比："
                        prop="separate_ratio"
                        :error="errorMessage?errorMessage.separate_ratio:''">
            <pushMoney class="width100"
                       :filterable="false"
                       @change="id=>form.separate_ratio=id"
                       placeholder="请选择分成比" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合作时间："
                        prop="contract_month"
                        :error="errorMessage?errorMessage.contract_month:''">
            <anchorMonth class="width100"
                         :filterable="false"
                         @change="id=>form.contract_month=id"
                         placeholder="请选择合作时间" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邀约状态："
                        prop="invite_state"
                        v-role="['administrator']"
                        :error="errorMessage?errorMessage.invite_state:''">
            <inviteState @change="id=>form.invite_state = id"
                         :filterable="false"
                         placeholder="请选择邀约状态"
                         class="width100"></inviteState>
          </el-form-item>
        </el-col>

        <el-col :span="12">

          <el-form-item label="来源："
                        prop="origin"
                        :error="errorMessage?errorMessage.origin:''">
            <el-select v-model="form.origin"
                       placeholder="请选择"
                       class="width100">
              <el-option v-for="item in [{
																							value:0,
																							label:'自招主播'
																						},
																						{
																							value:1,
																							label:'星探推荐'
																						}]"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="星探："
                        prop="star_id"
                        :error="errorMessage?errorMessage.star_id:''"
                        v-if="form.origin !==0">
            <div style="height:40px;width:100%;border:1px solid #DCDFE6;padding:0 15px;border-radius: 4px;display: flex;align-items:center;cursor:pointer"
                 class="flex align-center"
                 @click="open">
              <el-avatar :size="36"
                         style="margin-right:8px;"
                         v-show="anchorDetail.star_id"
                         :src="anchorDetail.star_user_avatar||''" />
              <div style="font-size:12px;"
                   :style="anchorDetail.star_id?'':'color:#ccc'">{{anchorDetail.star_user_name||'选择星探'}}</div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="王牌运营："
                        prop="star_broker_id"
                        v-if="form.origin !==0">
            <superBroker @change="id=>form.star_broker_id = id"
                         placeholder="请选择王牌运营"
                         :defaultvalue="form.star_broker_id"
                         class="width100"></superBroker>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="90">
        <el-col :span="24">
          <el-form-item label="备注："
                        prop="description"
                        :error="errorMessage?errorMessage.description:''">
            <el-input v-model="form.description"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 5}"
                      placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="primary"
                   @click="submit">确定</el-button>
      </el-row>
    </el-form>
    <customModal :visible="AddAnchorTips!==null"
                 @handleOk="closeAddAnchorTips"
                 :showCancelBtn="false">
      <div style="line-height: 30px;padding:0 20px">
        {{content}}
      </div>
    </customModal>
    <phoneModal @closeForm="closeForm"
                @anchorPhoneModal="anchorPhoneModal"
                :dialogPhoneForm="dialogPhoneForm"></phoneModal>
  </el-card>
</template>
 <script>
import brokerInviter from "components/formComponent/brokerInviter";
import mcn from "components/formComponent/mcn";
import pushMoney from "components/formComponent/pushMoney";
import inviteState from "components/formComponent/inviteState";
import anchorMonth from "components/formComponent/anchorMonth";
import phoneModal from "./components/phoneModal";
import superBroker from "components/formComponent/superBroker";
export default {
  components: {
    brokerInviter,
    mcn,
    pushMoney,
    inviteState,
    anchorMonth,
    phoneModal,
    superBroker
  },
  data () {
    return {
      dialogPhoneForm: false,
      form: {
        request_id: "",
        tick_account: "", //快手号
        broker_id: "", //经纪人
        mcn_id: "", //公会
        name: "", // 真实姓名
        phone: "", //电话
        separate_ratio: "", //分成比
        contract_month: "", // 合作时间
        invite_state: 0, //邀约状态
        description: "", //备注
        origin: 0,  //来源  0自招 1星探
        star_id: '', //星探id
        star_broker_id: null,  //王牌运营id
      },
      rules: {
        tick_account: [
          { required: true, message: "请输入快手号", trigger: "blur" },
        ],
        broker_id: [
          { required: true, message: "请选择经纪人", trigger: "blur" },
        ],
        mcn_id: [{ required: true, message: "请选择公会", trigger: "blur" }],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
        ],
        separate_ratio: [
          { required: true, message: "请选择分成比", trigger: "blur" },
        ],
        contract_month: [
          { required: true, message: "请选择合作时间", trigger: "blur" },
        ],
        invite_state: [
          { required: true, message: "请选择邀约状态", trigger: "blur" },
        ],
        origin: [
          { required: true, message: "请选择主播来源", trigger: "blur" },
        ],
        star_id: [
          { required: true, message: "请选择星探", trigger: "blur" },
        ],
        star_broker_id: [
          { required: true, message: "请选择王牌运营", trigger: "blur" },
        ]
      },
      errorMessage: null,
      reqLoading: false,
      content: "非欺诈招募保证（以直播平台官方头像或官方名义招募主播等行为，均属于欺诈招募！如招募方式不规范，请立即停止录入）",
      AddAnchorTips: true,
      anchorDetail: {    //星探资料
        star_user_avatar: null,
        star_user_name: '请选择星探',
        star_id: null
      }
    };
  },
  created () {
    this.$store.commit("layouts/setPathList", [
      { name: "主播列表", path: "/anchor/anchorList" },
      { name: "新增主播" },
    ]);
    this.$store.commit("layouts/changeBreadcrumb", true);
    // setTimeout(() => {
    //   this.$alert(
    //     "非欺诈招募保证（以直播平台官方头像或官方名义招募主播等行为，均属于欺诈招募！如招募方式不规范，请立即停止录入）",
    //     "",
    //     {
    //       confirmButtonText: "确定",
    //     }
    //   )
    //     .then(() => {
    //       this.dialogPhoneForm = true;
    //     })
    //     .catch(() => {
    //       this.dialogPhoneForm = true;
    //     });
    // }, 500);
    if (!this.role(["administrator"])) {
      this.form.broker_id = this.$store.state.user.userInfo.user_id;
    }
  },
  beforeDestroy () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },

  mounted () { },
  methods: {
    closeAddAnchorTips () {
      this.AddAnchorTips = null
      this.dialogPhoneForm = true;
    },
    closeForm () {
      if (!this.form.request_id) {
        this.$router.go(-1);
      }
    },
    async anchorPhoneModal (params) {
      const { status_code, message } = await this.$api.anchorCheck({
        ...params,
      });
      if (status_code === 200) {
        this.$message({
          type: "success",
          message: message.tip,
        });
        this.form.request_id = message.request_id;
        this.form.phone = params.phone;
        this.form.tick_account = params.tick_account;
        this.dialogPhoneForm = false;
      } else {
        if (typeof message === "object") {
          Object.keys(message).forEach((item) => {
            this.$message.error({ message: message[item] });
          });
        } else {
          this.$message.error({ message });
        }
      }
    },
    submit () {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._reqAnchorCreate();
        } else {
          return false;
        }
      });
    },
    // 请求添加主播
    async _reqAnchorCreate () {
      this.reqLoading = true;
      const { form } = this;
      const { status_code, message } = await this.$api.anchorCreate({
        ...form,
      });
      if (status_code === 200) {
        this.$router.go(-1);
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
    // 输入星探手机号
    open () {
      this.$prompt('请输入星探电话号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this._req_star_broker(value)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 获取星探资料
    async _req_star_broker (phone) {
      const { status, info, data } = await this.$api.getStarBroker({
        phone,
        zn_token: this.zn_token
      });
      if (status === 200) {
        this.anchorDetail.star_user_avatar = data.avatar
        this.anchorDetail.star_id = data.id
        this.form.star_id = data.id
        this.anchorDetail.star_user_name = data.nick_name
      } else {
        this.$message({
          message: info,
          type: "warning",
        });
      }
    },
  },
};
</script>
 <style lang="less" scoped>
.width100 {
  width: 100%;
}
.anchor-form-style /deep/ .el-input__inner {
  height: 40px;
}
.anchor-form-style /deep/ .el-form-item {
  margin-bottom: 40px;
}
</style>